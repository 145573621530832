import React, { useCallback, useMemo } from 'react';

import { Popover } from 'wix-ui-tpa';

import { CloseSmall } from '@wix/wix-ui-icons-common/on-stage';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { setRead as setReadThunk } from '@/features/messages/messagesThunks';
import { expandWidget } from '@/features/layout/layoutThunks';
import { selectMessages, selectLayout, selectIsWidgetOnline } from '@/features';
import { useAppDispatch, useAppSelector } from '@/store';

import { icons, iconsDict } from 'AiAssistantWidget/Settings/components';
import settingsParams from 'AiAssistantWidget/settingsParams';

import { Markdown } from '../Markdown';

import { MinimizedChatDataHooks } from './MinimizedChatDataHooks';
import { st, classes } from './MinimizedChat.st.css';

export const MinimizedChat = ({
  setRef,
}: {
  setRef: (ref: HTMLDivElement | null) => void;
}) => {
  const settings = useSettings();
  const dispatch = useAppDispatch();
  const { isMobile } = useEnvironment();
  const lastUnread = useAppSelector(selectMessages.lastUnread);
  const isOnline = useAppSelector(selectIsWidgetOnline);
  const wasOpened = useAppSelector(selectLayout.wasOpened);

  const expand = useCallback(() => {
    dispatch(expandWidget());
  }, [dispatch]);

  const setRead = useCallback(() => {
    dispatch(setReadThunk());
  }, [dispatch]);

  const hasLastUnread = useMemo(() => Boolean(lastUnread), [lastUnread]);

  const buttonText = useMemo(
    () =>
      settings.get(
        isOnline
          ? settingsParams.textButtonOnline
          : settingsParams.textButtonOffline,
      ),
    [settings, isOnline],
  );

  const stylesConfig = useMemo(() => {
    let pos = 'floating';
    const settingsPos = settings.get(settingsParams.minimizedButtonPosition);
    if (!isMobile && settingsPos) {
      pos = settingsPos;
    }

    let display = 'textIcon';
    const settingsDisplay = settings.get(settingsParams.minimizedButtonDisplay);
    if (isMobile) {
      display = 'icon';
    } else if (settingsDisplay) {
      display = settingsDisplay;
    }

    return {
      isLeft: settings.get(settingsParams.layout) === 'left',
      isSticky: pos === 'sticky',
      showIcon: pos === 'icon' || display === 'icon' || display === 'textIcon',
      showText:
        pos !== 'icon' && (display === 'text' || display === 'textIcon'),
      hasShadow: settings.get(settingsParams.minimizedButtonShadow) === true,
      icon: settings.get(settingsParams.minimizedButtonIcon) ?? icons[0].value,
    };
  }, [settings, isMobile]);

  return (
    <div
      className={st(classes.root, {
        left: stylesConfig.isLeft,
        sticky: stylesConfig.isSticky,
        nudge:
          settings.get(settingsParams.minimizedButtonNudgeAnimation) === true &&
          (!wasOpened || hasLastUnread),
      })}
      ref={setRef}
      data-hook={MinimizedChatDataHooks.ROOT}
    >
      <Popover
        shown={hasLastUnread}
        placement="top"
        showArrow={false}
        minWidth={220}
        contentClassName={st(classes.popover)}
      >
        <Popover.Element>
          <button
            onClick={expand}
            className={st(classes.button, {
              sticky: stylesConfig.isSticky,
              hasShadow: stylesConfig.hasShadow,
            })}
            data-hook={MinimizedChatDataHooks.BUTTON}
            aria-expanded="false"
            aria-describedby={hasLastUnread ? 'tooltip-content' : undefined}
          >
            {stylesConfig.showIcon && iconsDict[stylesConfig.icon]}
            {stylesConfig.showText ? (
              <div className={st(classes.text)}>{buttonText}</div>
            ) : (
              <span className="sr-only">{buttonText}</span>
            )}
          </button>
        </Popover.Element>
        <Popover.Content>
          <div className={st(classes.lastUnread)}>
            <button onClick={expand} className={st(classes.unreadText)}>
              <div
                id="tooltip-content"
                className={st(classes.unreadTextContent)}
              >
                <Markdown text={lastUnread?.body?.mainText ?? ''} />
              </div>
            </button>
            <button className={st(classes.closeUnread)} onClick={setRead}>
              <CloseSmall />
            </button>
          </div>
        </Popover.Content>
      </Popover>
    </div>
  );
};
